import React from 'react';
import logo from './iSimple-logo-2.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We are coming soon...
        </p>
      </header>
      <footer className="App-footer">
        <p>&copy;常州森普信息科技有限公司</p>
        <p>&copy;Changzhou iSimple Information Technology Co., Ltd</p>
        <a href="https://beian.miit.gov.cn">苏ICP备19041399号</a>
      </footer>
    </div>
  );
}

export default App;
